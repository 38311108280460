<template>
  <div style="min-height: 70vh;" class="wrapper">
    <section class="account-details px-0 py-0">
      <div class="card p-1 s-a-1" style="background-color: var(--darkBackground);">
        <div class="row px-1 py-1 m-0 mb-2">
          <div class="col-12">
            <div class="line-height-0 row" v-if="profile.new_profile === false">
              <div class="col-1">
                <img src="/img/menu/user-icon.png" class="menu-icon-svg">
              </div>
              <div class="col-11">
                <strong class="text-light">{{ myProfile.first_name }} {{ myProfile.last_name }}</strong><br />
                <strong class="text-light">{{ myProfile.msisdn }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-1 py-1 m-0">
          <div class="col-6">
            <div style="border: solid gray 1px;padding: 6px;border-radius: 5px;margin: 2px;" class="line-height-0 row">
              <div class="col-12">
                <span style="color: var(--white);" class="player-profile-details s-a-1-txt">Bonus</span><br />
                <strong class="text-light">{{ myBonusBalance }}</strong>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div style="border: solid gray 1px;padding: 6px;border-radius: 5px;margin: 2px;" class="account row">
              <div class="col-12">
                <span style="color: var(--white);float: left;" class="s-a-1-txt">Balance</span><br />
                <strong class="text-light">Ksh. <span v-text="bal"></span></strong>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="row px-1 py-1 m-0 button-row">
          <div class="col-6">
            <div class="line-height-0">
              <button
                :style="depositPage ? { backgroundColor: 'var(--yellow)', color: 'var(--grey)' } : { backgroundColor: '#101B21', color: 'white', border: 'solid yellow 1px' }"
                id="deposit-profile" :class="{ 'active': depositPage, 'loading': loading }"
                class="action-button py-2 form-control" @click="showDeposit">
                Deposit
              </button>
            </div>
          </div>
          <div class="col-6">
            <div class="account">
              <button
                :style="withdrawalPage ? { backgroundColor: 'var(--yellow)', color: 'var(--grey)' } : { backgroundColor: '#101B21', color: 'white', border: 'solid yellow 1px' }"
                id="withdrawal-profile" :class="{ 'active': withdrawalPage, 'loading': loading }"
                class="action-button py-2 form-control" @click="showWithdrawal">
                Withdraw
              </button>
            </div>
          </div>
          <hr class="m-1" />

          <div class="col-12" v-show="depositPage === true">
            <div class="card p-1 s-a-1" style="background-color: var(--white);color: var(--darkBackground);">
              <div class="text-dark text-center matches-title mb-1">
                <h4>Deposit</h4>
                <span style="color: var(--darkBackground);" class="s-a-1-txt">Top up your Powerbet Account</span>
              </div>
              <section class="px-2 mb-3">
                <div class="form-wrapper1 s-a-1 mb-0">
                  <div class="mb-0">
                    <input
                      style="border: 1px black solid;border-radius: 5px;background-color: var(--white);color: black;width: 100%;height: 30px;text-align: center;"
                      type="number" name="amount" id="deposit" placeholder="10" min="0" class="form-control" value="99"
                      aria-label="Amount (to the nearest dollar)" v-model="amount" />
                  </div>
                  <br />
                  <div class="d-flex justify-content-between mb-2">
                    <div class="depo-btn-wrapper">
                      <button style="background-color: var(--darkBackground);color: var(--white);"
                        class="btn odd-btn px-2 sym-button" @click="setAmount(20)">
                        +<span class="deposit_val">20</span>
                      </button>
                    </div>
                    <div class="depo-btn-wrapper">
                      <button style="background-color: var(--darkBackground);color: var(--white);"
                        class="btn odd-btn px-2 sym-button" @click="setAmount(50)">
                        +<span class="deposit_val">50</span>
                      </button>
                    </div>
                    <div class="depo-btn-wrapper">
                      <button style="background-color: var(--darkBackground);color: var(--white);"
                        class="btn odd-btn px-2 sym-button" @click="setAmount(100)">
                        +<span class="deposit_val">100</span>
                      </button>
                    </div>
                    <div class="depo-btn-wrapper">
                      <button style="background-color: var(--darkBackground);color: var(--white);"
                        class="btn odd-btn px-2 sym-button" @click="setAmount(500)">
                        +<span class="deposit_val">500</span>
                      </button>
                    </div>
                  </div>
                  <small style="color: var(--darkBackground);" class="s-a-1-txt mt-3 mb-3">Minimum Deposit amount: Ksh
                    10</small>

                  <button style="background-color: var(--darkBackground);color: var(--white);" @click="deposit"
                    id="deposit-profile" v-bind:class="loading" class="copy-betslip-btn1 py-2 form-control">
                    Deposit
                  </button>
                </div>
              </section>
              <div class="slip-ttxt py-3">
                <div class="text-dark mb-3">
                  <a style="border: solid black 1px;" @click="logout" class="login-button py-2 px-4 ">
                    <span style="color: black;">Logout</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" v-show="withdrawalPage === true">
            <div class="card p-1 s-a-1" style="background-color: var(--white);color: var(--white);">
              <div class="text-dark text-center matches-title mb-1 pt-2">
                <h4>
                  Withdraw
                </h4>
                <span style="color: var(--darkBackground);" class="s-a-1-txt">Withdraw funds from your Powerbet
                  account</span>
              </div>
              <section class="px-2 mb-3">
                <div class="form-wrapper s-a-1 mb-0">
                  <div class="mb-0">
                    <input
                      style="border: 1px black solid;border-radius: 5px;background-color: var(--white);color: black;width: 100%;height: 30px;text-align: center;"
                      type="number" class="form-control" name="amount" placeholder="100" min="0" aria-label="Amount"
                      v-model="withdraw_amount" />
                    <br />
                  </div>
                  <small style="color: var(--darkBackground);" class="s-a-1-txt mb-3 mt-3">Minimum Ksh. 100 Maximum Ksh.
                    70,000</small>
                  <button style="background-color: var(--darkBackground);color: var(--white);" @click="withdraw"
                    v-bind:class="loading" class="copy-betslip-btn1 py-2 form-control"
                    :disabled="loading === 'loading'">
                    {{ loading === 'loading' ? 'Processing...' : 'Withdraw' }}
                  </button>
                </div>
              </section>
              <div class="slip-ttxt py-3">
                <div class="text-dark mb-3">
                  <a style="border: solid black 1px;" @click="logout" class="login-button py-2 px-4 ">
                    <span style="color: black;">Logout</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card p-1 s-a-1" style="background-color: var(--white);color: var(--darkBackground);">
              <div class="text-dark text-center matches-title mb-1 pt-2">
                <h4>Transaction History</h4>
              </div>
              <section class="px-2 mb-3">
                <div class="row">
                  <div class="col-6">
                    <button class="transaction-button" @click="fetchTransactions('deposit')">
                      User Deposits
                    </button>
                  </div>
                  <div class="col-6">
                    <button class="transaction-button" @click="UserTransactions('withdrawal')">
                      User Withdrawals
                    </button>
                  </div>
                </div>
                <div v-if="transactions.length > 0">
                  <ul>
                    <li v-for="transaction in transactions" :key="transaction.id">
                      {{ transaction.description }} - {{ transaction.amount }} - {{ transaction.created }}
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p>No transactions available</p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
    <span id="open-shareinvite" class="hidden"></span>
  </div>
</template>

<script>
import walletserve from "@/services/walletserve";
import bonusserve from "@/services/bonus";

export default {
  name: "Setting",
  components: {},
  data: function () {
    return {
      amount: "",
      mb8Balance: "0",
      loading: "",
      pro: {},
      bonus_code: "",
      errorDisplayed: false,
      withdraw_amount: "",
      new_referral_code: "",
      errors: [],
      code: "",
      link: "",
      copyText: "Copy",
      copyBookingCode: "Click to copy",
      myProfile: this.getProfile(),
      depositPage: true,
      withdrawalPage: false,
      myBonusBalance: 0,
      transactions: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "My Powerbet Account";
        document.description = "Manage Powerbet account";
      },
    },
    new_referral_code: function (n) {
      console.log("got new value here " + n);

      this.validateReferralLink();
    },
  },
  created() {
    var profile = this.getProfile();

    // Check if the user is logged in
    var isLoggedIn = profile && profile.a;

    if (isLoggedIn) {
      this.EventBus.$on("mb8:updated", (mb8balance) => {
        this.receivedMB8Balance = mb8balance;
      });
      this.EventBus.$on("bonus:updated", (bonus) => {
        this.receivedBonus = bonus;
      });
    }
  },
  mounted() {
    this.fetchMyBonusBalance();
    this.$store.dispatch("setCurrentPage", "setting");
    var vm = this;

    vm.myProfile = vm.getProfile();
    // console.log("My Profile data", vm.myProfile);
    this.reloadProfile();

    this.getBonus();

    if (!vm.myProfile) {
      this.setError("Login", "Please login to proceed");
      this.$router.push({ name: "login", params: {} });
      return;
    }

    setInterval(function () {
      vm.myProfile = vm.getProfile();
    }, 1000);

    this.code = vm.myProfile.referral_code;
    // this.link = vm.myProfile.referral_link;
    this.initShareInviteModal();
  },
  methods: {
    fetchMyBonusBalance() {
      var vm = this;

      const path = process.env.VUE_APP_BONUS_BASE_URL + "/bonus/balance";
      // console.log(this.getAuth())

      bonusserve.get(path, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': vm.getAuth()
        }
      })
        .then(response => {
          this.myBonusBalance = response.data.balance;
          console.log('Success bonus balance:', response.data.balance);
        })
        .catch(err => {
          console.error('Error bonus balance:', err);
        });
    },
    showDeposit() {
      this.depositPage = true;
      this.withdrawalPage = false;
    },
    showWithdrawal() {
      console.log(this.getAuth());
      this.withdrawalPage = true;
      this.depositPage = false;
    },
    setAmount: function (amountToAdd) {
      // Convert the current amount to a number
      let currentAmount = parseFloat(this.amount);

      // Check if the current amount is a valid number
      if (isNaN(currentAmount)) {
        currentAmount = 0; // Set it to 0 if it's not a valid number
      }

      // Add the new amount to the existing amount
      currentAmount += amountToAdd;

      // Update the amount with the new total
      this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
    },

    generateOrRegenerateReferralCode: function () {
      this.reset();
      this.errors = [];

      var p = this.getProfile();
      var ref = p.referral_code;
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      return ref;
    },

    redeemBonus: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_BONUS_URL + "/campaign/redeem";

      var payload = {
        bonus_code: String(this.bonus_code),
      };

      bonusserve
        .post(path, JSON.stringify(payload), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          // vm.loading = "";
          console.log("Response for Bonus", res);
          // var bonusdata = res.data.data;

          vm.bonusdata = res.data.data; 7
        })
        .catch((err) => {
          console.log("Error", err);
          // vm.loading = "";
          if (err.response) {
            // console.log(JSON.stringify(err.request));
          } else {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            // console.log(JSON.stringify(err));
          }
        });
    },

    deposit: function () {
      console.log(this.getAuth());
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.setError("Invalid Amount", "Enter amount atleast 10 KSH or above");
        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_WALLET_BASE_URL + "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      // vm.loading = "loading";
      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          // vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
        })
        .catch((err) => {
          // vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    withdraw: function () {

      if (this.loading === 'loading') {
        return;

      }

      var vm = this;

      setTimeout(function () {
        vm.loading = '';
      }, 5000);

      this.reset();

      var p = this.getAuth();

      if (!p) {

        this.loading = '';
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.withdraw_amount < 100) {

        this.setError(
          "Invalid Amount",
          "Enter amount at least 100 KSH or above"
        );
        return;
      }

      var path = process.env.VUE_APP_WALLET_BASE_URL + "/withdraw";

      var data = {
        amount: parseInt(this.withdraw_amount),
        // msisdn: parseInt(this.msisdn),
      };

      // this.loading = 'loading';

      walletserve
        .post(path, data, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          console.log("Response for Withdraw", res)
          // vm.loading = "";
          var msg = res.data.data;
          if (parseInt(res.data.status) === 200) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          if (parseInt(res.data.status) === 201) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
        })
        .catch((err) => {
          console.log("Error", err)
          // vm.loading = "";
          var msg = err.response.data.error_message;
          if (!vm.errorDisplayed) {
            if (parseInt(err.response)) {
              vm.setError("Failed", msg);
            } else if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
            } else {
              vm.setError("Failed", msg);
              // console.log(JSON.stringify(err.response.data.message));
            }
            vm.errorDisplayed = true;
          }
        });
    },

    fetchTransactions(type) {
      const transactionType = type === 'deposit' ? 11 : 9; // Based on API documentation
      const path = `${process.env.VUE_APP_WALLET_BASE_URL}/transactions?page=1&per_page=10&sort=id|desc&transaction_type=0&reference_id=${transactionType}`;

      walletserve.get(path, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': this.getAuth(),
        }
      })
        .then(response => {
          this.transactions = response.data.data;
        })
        .catch(err => {
          console.error('Error fetching transactions:', err);
        });
    },

    shareInvite: function () {
      document.getElementById("open-shareinvite").click();
    },
    copyShareBetLink: function () {
      var profile = this.getProfile();
      if (!profile) {
        return;
      }
      var link = "https://Powerbet/join?p=" + profile.referral_code;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },

    copyCode: function () {
      var link = "accept#" + this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = "Invite Code Copied";
    },

    initShareInviteModal: function () {
      var modal = document.getElementById("shareinvite-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-shareinvite");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      };

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("#shareinvite-modal")) {
          modal.style.display = "none";
        }
      });
    },
  },

  computed: {
    bonus: function () {

      return this.formatCurrency(this.$store.state.bonusBalance);

    },

    bal: function () {

      return this.formatCurrency(this.$store.state.walletBalance);

    },
    myBalance: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    myBonus: function () {
      return this.$store.state.bonus;
    },
    msg: function () {
      return "Karibu Powerbet, Come let's win BIG together.";
    },
    profile: function () {
      return this.myProfile;
    },
    // has_referral_code: function () {
    //   return this.code !== undefined && this.code.length > 3;
    // },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2023-11-22T12:00:00");
      const endDate = new Date("2023-11-22T15:30:00");

      return now >= startDate && now <= endDate;
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "KES",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
};
</script>

<style scoped>
.action-button,
.transaction-button {
  background-color: #101B21;
  color: white;
  border: solid 1px yellow;
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.action-button.active {
  background-color: var(--yellow);
  color: var(--grey);
}

.action-button.loading {
  cursor: not-allowed;
}

.transaction-button {
  background-color: var(--darkBackground);
  color: white;
  border: solid 1px yellow;
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
}

.transaction-button:hover {
  background-color: var(--yellow);
  color: var(--darkBackground);
}

.sym-button {
  background-color: var(--darkBackground);
  color: var(--white);
  border: solid 1px var(--yellow);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sym-button:hover {
  background-color: var(--yellow);
  color: var(--darkBackground);
}
</style>