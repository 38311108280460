<template>

  <div style="min-height: 70vh;" class="wrapper">

    <section class="register-form-wrapper p-4" v-show="action_login">
      <h3 class="text-light text-center"> <span class="text-light">Login</span> </h3>
      <div class="text-center text-light mb-3">
        Enter your phone number and Password below
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-0 d-flex justify-content-center">
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;"
              type="tel" class="form-control" placeholder="Phone number 07 or 01" aria-label="Enter Phone number"
              name="mobile" v-model="msisdn">
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center">Invalid phone number</small>
          <div class="mb-3"></div>
          <div class="mb-0 d-flex justify-content-center">
            <div class="input-group"
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;">
              <input :type="type" class="form-control" id="inputPass"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;" name="password"
                placeholder="Enter Password" aria-label="Enter Password" v-model="password">
              <div class="input-group-prepend"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;">
                <span style="background-color: var(--yellow);" class="btn btn-sm" type="button"><img
                    @click="showPassword" style="width: 20px;" :src="btnText"></span>
              </div>
            </div>
          </div>
          <small class="text-blue mb-3 d-none">Enter the 4 digit code sent to your phone</small>
          <input type="hidden" name="utm_source" value="">
          <input type="hidden" name="utm_medium" value="">
          <input type="hidden" name="utm_campaign" value="">
          <input type="hidden" name="referrer" value="">
        </form>
      </div>

      <div class="disclaimer text-center d-none text-light">
        By continuing, you confirm that you are 18 years old or over and agree with the <a
          class="text-light">Terms, conditions and policies</a> of Powerbet
      </div>

      <div class="button-wrapper text-center mb-3 d-flex justify-content-center">
        <a style="width: 350px;" v-if="!spinner" @click="login" class="join-button py-2 form-control">Login</a>
        <a style="width: 350px;" v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br/>
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Did you forget your Password? <a @click="setGetResetCode"> <span
              style="text-decoration: underline;">Reset</span></a>
          <br>
          <br>
          <div class="text-center text-light mb-3">
            Don't have an account? <a @click="setSignup"> <span style="text-decoration: underline;">Join Now</span></a>
            <br>
          </div>
        </div>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_signup">
      <h3 class="text-light text-center">Karibu <span class="text-light">
        <img style="width: 150px;" src="/powerbet.png" alt="Powerbet Logo">
      </span> </h3>
      <div class="text-light text-center mb-3"></div>
      <div class="form-wrapper mb-3">
        <form class="">
          <div class="mb-0 d-flex justify-content-center">
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;"
              type="tel" class="form-control" placeholder="Phone number 07 or 01" aria-label="Enter Phone Number"
              name="mobile" id="msisdn" v-model="msisdn">
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center m-auto">Invalid phone number</small>
          <div class="mb-3"></div>
          <div class="mb-3 d-flex justify-content-center">
            <div class="input-group d-flex justify-content-center"
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;">
              <input :type="type" class="form-control" id="inputPass"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;" name="password"
                placeholder="Enter Password" aria-label="Enter Password" v-model="password">
              <div class="input-group-prepend"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;">
                <span style="background-color: var(--yellow);" class="btn btn-sm" type="button"><img
                    @click="showPassword" style="width: 20px;" :src="btnText"></span>
              </div>
            </div>
          </div>
          <div class="mb-3 d-flex justify-content-center">
            <div class="input-group d-flex justify-content-center"
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;">
              <input :type="type" class="form-control" id="signupPassword1"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;" name="password_2"
                placeholder="Confirm Password" aria-label="Confirm Password" v-model="password1">
              <div class="input-group-prepend"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;">
                <span style="background-color: var(--yellow);" class="btn btn-sm" type="button"><img
                    @click="showPassword" style="width: 20px;" :src="btnText"></span>
              </div>
            </div>
          </div>
          <!-- <div class="mb-0 d-flex justify-content-center">
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;"
              type="tel" class="form-control" placeholder="Enter referral code" aria-label="Enter referral code"
              name="mobile" id="referral_code" v-model="referral_code">
          </div> -->
        </form>
      </div>

      <div class="disclaimer text-center text-light">
        By registering, you confirm that you are 18 years old or over and agree with the <a
          class="text-light">Terms and Conditions and Privacy Policy</a> of Powerbet
      </div>

      <div class="button-wrapper text-center mb-3 d-flex justify-content-center">
        <a style="width: 350px;" v-if="!spinner" @click="signup" class="join-button py-2 form-control">Get registration code</a>
        <a style="width: 350px;" v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br/>
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3 d-flex justify-content-center">
          Already have an account? <a @click="setLogin"> <span style="text-decoration: underline;"> Login</span></a>
          <br>
        </div>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_verify_password">
      <h3 class="text-light text-center">Registration <span class="text-light">Code</span> </h3>
      <div class="text-center text-light mb-3">
        Check your phone for an SMS verification code
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-0 d-flex justify-content-center">
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;"
              type="number" class="form-control" placeholder="Enter code" id="code" aria-label="Enter Code"
              v-model="code">
          </div>
          <div class="text-blue mb-3 text-center">Enter the 4 digit code sent to your phone</div>
        </form>
      </div>

      <div class="disclaimer text-center text-light">
        By activating your Powerbet account, you confirm that you are 18 years old or over and agree with the <a
          class="text-light">Terms, conditions and policies</a> of Powerbet
      </div>

      <div class="button-wrapper text-center mb-3 d-flex justify-content-center">
        <a style="width: 350px;" v-if="!spinner" @click="verifyAccount" class="join-button py-2 form-control">ACTIVATE ACCOUNT</a>
        <a style="width: 350px;" v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br/>
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Didn’t get an SMS code?<br>
          Let’s try again in<br>
          (15 seconds)
        </div>
        <a href="#n" class="login-button py-2 form-control">Resend</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_reset_password">
      <h3 class="text-light text-center">Reset your <span class="text-light">Password</span> </h3>
      <div class="text-center text-light mb-3">
        Enter your phone number
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3 d-flex justify-content-center">
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;"
              type="text" class="form-control" id="msisdn1" placeholder="Phone number 07 or 01"
              aria-label="Amount (to the nearest dollar)" v-model="msisdn">
          </div>

        </form>
      </div>

      <!--<div class="disclaimer text-center d-none">
        By cont for Powerbet, you confirm that you are 18 years old or over and agree with the <a class="text-light">Terms, conditions and policies</a>  of Powerbet
      </div>-->

      <div class="button-wrapper text-center mb-3 d-flex justify-content-center">
        <a style="width: 350px;" v-if="!spinner" @click="getResetCode" class="join-button py-2 form-control">Get Reset Code</a>
        <a style="width: 350px;" v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br/>
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3 d-flex justify-content-center">
          Already have an account? <a @click="setLogin"> <span style="text-decoration: underline;"> Login</span></a><br>
        </div>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_change_password">
      <h3 class="text-light text-center">Reset your <span class="text-light">Password</span> </h3>
      <div class="text-center text-light mb-3">
        Password reset code has been send to your mobile number. Enter the code you have received in your phone and your
        new password.
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3 d-flex justify-content-center">
            <input
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;"
              type="text" class="form-control" placeholder="Enter code" id="code1" aria-label="Enter Code"
              v-model="code">
          </div>
          <div class="mb-3 d-flex justify-content-center">
            <div class="input-group"
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;">
              <input :type="type" class="form-control" id="signupPassword3"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;" name="password"
                placeholder="New Password" aria-label="New Password" v-model="password">
              <div class="input-group-prepend"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;">
                <span style="background-color: var(--yellow);" class="btn btn-sm" type="button"><img
                    @click="showPassword" style="width: 20px;" :src="btnText"></span>
              </div>
            </div>
          </div>
          <div class="mb-0 d-flex justify-content-center">
            <div class="input-group"
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 350px;height: 40px;text-align: center;">
              <input :type="type" class="form-control" id="signupPassword1"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;" name="password_2"
                placeholder="Confirm New Password" aria-label="Confirm New Password" v-model="password1">
              <div class="input-group-prepend"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;">
                <span style="background-color: var(--yellow);" class="btn btn-sm" type="button"><img
                    @click="showPassword" style="width: 20px;" :src="btnText"></span>
              </div>
            </div>
          </div>


        </form>
      </div>

      <div class="disclaimer text-center d-none text-light">
        By cont for Powerbet, you confirm that you are 18 years old or over and agree with the <a
          class="text-light">Terms, conditions and policies</a> of Powerbet
      </div>

      <div class="button-wrapper text-center mb-3 d-flex justify-content-center">
        <a style="width: 350px;" v-if="!spinner" @click="changePassword" class="join-button py-2 form-control">Change Password</a>
        <a style="width: 350px;" v-if="spinner" class="join-button py-2 form-control">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            <br/>
          </div>
        </a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3 d-flex justify-content-center">
          Already have an account? <a @click="setLogin"> <span style="text-decoration: underline;"> Login</span></a><br>
        </div>
      </div>

    </section>
  </div>

</template>

<style scoped>
.input-signup,
.input-login {
  border: 1px solid #ccc;
  border-radius: .5em;
  height: 3em;
}
</style>

<script>
import axios from "@/services/identity";

export default {
  name: 'Login',
  components: {
  },
  comments: {

  },
  data: function () {

    return {
      msisdn: '',
      password: '',
      password1: '',
      error: [],
      warning: [],
      success: [],
      loading: '',
      spinner: false,
      action_login: false,
      action_signup: true,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: '',
      placeBet: 0,
      type: 'password',
      type2: 'password',
      type3: 'password', btnText: '/img/icons/eye.png',
      btnText2: '/img/other/eye.png',
      btnText3: '/img/other/eye.png'
    }
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }

    }
  },
  methods: {
    login: function () {

      this.reset();
      this.removeAuth();

      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.spinner = true;
      this.loading = 'loading';

      var vm = this;

      var path = "/login";

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
       

      console.log('here we are')

      axios.post(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer
      }))
        .then(res => {

          vm.spinner = false;
          vm.loading = '';

          var profile = res.data;
          var status = res.status;

          console.log("status " + status);

          if (parseInt(status) === 201 || parseInt(status) === 204) {

            // take password verification code
            vm.setVerifyAccount();
            return;

          }

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);
          console.log("profile " + profile.t);


          vm.setSuccess("Success", "Login successful");
          vm.EventBus.$emit('init:mqtt');
          window.location.href = "/";
        })
        .catch(err => {

          vm.spinner = false;
          vm.loading = '';

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        document.getElementById('phoneInput').classList.add("is-invalid")

      } else {
        document.getElementById('phoneInput').classList.remove("is-invalid")
      }
    },
    signup: function () {

      this.reset();

      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      this.spinner = true;
      this.loading = 'loading';

      var vm = this;

      var path = "/signup";

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
       
       
      // var referralCodeToSend = this.referralCode;
      var btag = this.getValue("btag")

      axios.post(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
        referral_code: this.referralCode,
        // referral_code: String(referralCodeToSend),
        btag: btag
      }))
        .then(res => {

          vm.spinner = false;
          vm.loading = '';

          console.log('rs ' + JSON.stringify(res, undefined, 2))

          const msg = res.data.data;

          // var profile = res.data.message;
          var status = res.data.status;

          if (parseInt(status) === 202) {

            vm.setSuccess("Success", "Registration successful. Please login to proceed");
            vm.setLogin();
            return;

          }


          vm.setSuccess("Signup successful", msg);

          // go to password verification page
          vm.setVerifyAccount();

        })
        .catch(err => {

          vm.spinner = false;
          vm.loading = '';

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    getResetCode: function () {

      this.reset();

      if (this.msisdn.length < 9) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;
      }

      this.spinner = true;
      this.loading = 'loading';

      var vm = this;
      var path = "/password/forgot";

      var payload = {
        msisdn: parseInt(this.msisdn)
      };

      console.log("Request Payload:", payload);

      axios.patch(path, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          vm.spinner = false;
          vm.loading = '';
          console.log(JSON.stringify(res));
          vm.setSuccess("Success", "Password reset code has been sent to your phone");
          // go to password reset page
          vm.setChangePassword();
        })
        .catch(err => {
          vm.spinner = false;
          console.log(JSON.stringify(err));

          if (err.response) {
            vm.setError("Failed", err.response.data.message);
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log(JSON.stringify(err.request));
          } else {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log(JSON.stringify(err));
          }
        });
    },

    changePassword: function () {

      this.reset();

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.spinner = true;
      this.loading = 'loading';

      var vm = this;

      var path = "/password/reset";

      var payload = {
        msisdn: parseInt(this.msisdn),
        password: this.password,
        code: parseInt(this.code)
      };

      console.log("Request Payload:", payload);

      axios.patch(path, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          console.log(JSON.stringify(res));

          vm.spinner = false;
          vm.loading = '';
          vm.setSuccess("Password Changed", "Your password has been changed successfully,. Please login to continue");

          // go to login page
          vm.setLogin();

        })
        .catch(err => {

          vm.spinner = false;
          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.message)
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    verifyAccount: function () {
      this.reset();

      if (this.code.length < 4) {
        this.setError("Invalid Code", "Please enter a valid verification code");
        return;
      }

      this.spinner = true;
      this.loading = 'loading';
      var vm = this;
      var path = "/verify";
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || '';
      var utm_medium = this.getValue("utm_medium") || '';
      var utm_campaign = this.getValue("utm_campaign") || '';

      var payload = {
        msisdn: parseInt(this.msisdn),
        code: parseInt(this.code),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer
      };

      console.log("Request Payload:", payload);

      axios.post(path, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          vm.spinner = false;
          vm.loading = '';
          var profile = res.data;
          var status = res.status;

          console.log("Response Status:", status);
          console.log("Response Data:", profile);

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
          vm.EventBus.$emit('init:mqtt');

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');
          } else {
            vm.goHome();
          }
        })
        .catch(err => {
          vm.spinner = false;
          vm.loading = '';
          console.log("Error:", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            var errorMessage = err.response.data || "An error occurred";
            vm.setError("Failed", errorMessage);
          } else if (err.request) {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log("Error Request:", err.request);
          } else {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log("Error Message:", err.message);
          }
        });
    },
    setSignup: function () {

      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setLogin: function () {

      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setGetResetCode: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setChangePassword: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setVerifyAccount: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;

    },
    showPassword: function () {
      if (document.getElementById('inputPass').type == "password") {
        document.getElementById('inputPass').type = "text"
        document.getElementById('inputPass').style.color = "white"
      } else {
        document.getElementById('inputPass').type = "password"
        document.getElementById('inputPass').style.color = "rgba(255,255,255,.75)"
      }
      // if(document.getElementById('signupPassword1').type == "password"){
      //   document.getElementById('signupPassword1').type = "text"
      // }else{
      //   document.getElementById('signupPassword1').type = "password"
      // }
      if (this.type === 'password') {
        this.type = 'text'
        this.btnText = '/img/icons/eye-slash.png'
      } else {
        this.type = 'password'
        this.btnText = '/img/icons/eye.png'
      }
    },

    showPasswordReset: function () {
      if (document.getElementById('signupPassword3').type == "password") {
        document.getElementById('signupPassword3').type = "text"
        document.getElementById('signupPassword3').style.color = "white"
      } else {
        document.getElementById('signupPassword3').type = "password"
        document.getElementById('signupPassword3').style.color = "rgba(255,255,255,.75)"
      }
      if (this.type2 === 'password') {
        this.type2 = 'text'
        this.btnText2 = '/img/other/eyeslash.svg'
      } else {
        this.type2 = 'password'
        this.btnText2 = '/img/other/eye.png'
      }
    },
    showPasswordReset2: function () {
      if (document.getElementById('signupPassword4').type == "password") {
        document.getElementById('signupPassword4').type = "text"
        document.getElementById('signupPassword4').style.color = "white"
      } else {
        document.getElementById('signupPassword4').type = "password"
        document.getElementById('signupPassword4').style.color = "rgba(255,255,255,.75)"
      }
      if (this.type3 === 'password') {
        this.type3 = 'text'
        this.btnText3 = '/img/other/eyeslash.svg'
      } else {
        this.type3 = 'password'
        this.btnText3 = '/img/other/eye.png'
      }
    }
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "join");
    this.placeBet = this.getValue("placeBet");


  }
}
</script>